import './App.css';
import Masonry from 'react-masonry-css'
import React, { useState } from 'react';
import CookieConsent from "react-cookie-consent";
import ReactGA from "react-ga";

ReactGA.initialize('UA-187033153-1', {debug: false})

const breakpointColumnsObj = {
  default: 4,
  1000: 3,
  600: 2
}; 

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      locations: [],
      businesses: [],
      opengroup: "none",
      maingroup: "none",
      filter: 1,
      filtergroup: "Status",
      data: [],
      OverlayVis: "none",
      Overlay: [],
      VisibleNo: 20,
      MenuTop: '100vh',
      CloseButton: 'none',
      LoadMoreVis: 'block',
      Password: '',
      BlockScreen: '',
      MainApp:'',
      mainappvis:''
    }
  }


  allloaded = () => {
    if (this.state.data.filter(items => items[this.state.filtergroup] === this.state.filter).length <= this.state.VisibleNo)
    this.setState({LoadMoreVis: 'none'});
    if (this.state.data.filter(items => items[this.state.filtergroup] === this.state.filter).length > this.state.VisibleNo)
    this.setState({LoadMoreVis: 'block'})
  }

  allloadedcheck = () => {
    this.setState({ VisibleNo: this.state.VisibleNo+10});
    this.allloaded();
  }

  authenticate(){
    return new Promise(resolve => setTimeout(resolve, 3000)) // 1 seconds
  }

  GAtileopen(data){
    this.setState({ OverlayVis: 'block', Overlay: data })
    GAEvent(this.state.Overlay.Name, 4, 'TileClick')
  }

  componentDidMount() {

    fetch('https://ldnfoodmerch.herokuapp.com/businessmenu')
      .then(response => response.json())
      .then(menu => this.setState({ businesses: menu }))

    fetch('https://ldnfoodmerch.herokuapp.com/locationmenu')
      .then(response => response.json())
      .then(menu => this.setState({ locations: menu }))

    fetch('https://ldnfoodmerch.herokuapp.com/clothesmenu')
      .then(response => response.json())
      .then(menu => this.setState({ items: menu }))

    fetch('https://ldnfoodmerch.herokuapp.com/data')
      .then(response => response.json())
      .then(data_ => this.setState({ data: data_ }))

    this.authenticate().then(() => {
      const ele = document.getElementById('loader')
      if(ele){
        // fade out
        ele.classList.add('available')
        setTimeout(() => {
          // remove from DOM
          ele.outerHTML = ''
        }, 500)
      }
      this.setState({mainappvis:'block'})
    }
    )
  }

  render() {
    var itemsgroups = [], itemoutputs = [], l = this.state.items.length, i;
    for (i = 0; i < l; i++) {
      if (itemsgroups[this.state.items[i].GroupDesc]) continue;
      itemsgroups[this.state.items[i].GroupDesc] = true;
      itemoutputs.push(this.state.items[i].GroupDesc);
    }
    var locationgroups = [], locationoutput = [], k = this.state.locations.length, j;
    for (j = 0; j < k; j++) {
      if (locationgroups[this.state.locations[j].NSEW]) continue;
      locationgroups[this.state.locations[j].NSEW] = true;
      locationoutput.push(this.state.locations[j].NSEW);
    }
    var businessgroups = [], businessoutput = [], m = this.state.businesses.length, h;
    for (h = 0; h < m; h++) {
      if (businessgroups[this.state.businesses[h].BusDesc]) continue;
      businessgroups[this.state.businesses[h].BusDesc] = true;
      businessoutput.push(this.state.businesses[h].BusDesc); 
    }


    return (
      <div className="App" style={{display: this.state.mainappvis}}>
        <div style={{ marginTop: '68px', marginBottom:'10vh', display:this.state.MainApp}}>
          <div className="overlaymobile" id="overlay" key={this.state.Overlay.URL} style={{ display: this.state.OverlayVis, width: '100%' }}>
            <div className="tileoverlay" onClick={() => this.setState({ OverlayVis: 'none', MenuTop: '100vh' })}/> 
              <div className = "overlaybox" >
                <div className = "closepopup" onClick={() => this.setState({ OverlayVis: 'none', MenuTop: '100vh' })}>✕</div>
                <img className="overlaypic" src={"/img/" + this.state.Overlay.CompID + "/" + this.state.Overlay.MainImg} alt='' />
                <a className="button1" onClick={() => GAEvent(this.state.Overlay.Name + ' - ' + this.state.Overlay.CompID, 1, 'PageLeave')} href={this.state.Overlay.URL} target="_blank" rel='noreferrer'>{this.state.Overlay.Name}</a>
                <a className="button2" onClick={() => GAEvent(this.state.Overlay.Name + ' - ' + this.state.Overlay.CompID, 2, 'PageLeave')}  href={this.state.Overlay.CompURL} target="_blank" rel='noreferrer'>{this.state.Overlay.Company}</a>
                <a className="button2" onClick={() => GAEvent(this.state.Overlay.Name + ' - ' + this.state.Overlay.CompID, 3, 'PageLeave')}  href={'http://www.instagram.com/' + this.state.Overlay.Insta} target="_blank" rel='noreferrer'><img alt='instalogo' src="\img\Icons\instagram.svg" className='instalogo' />{this.state.Overlay.Insta}</a>
              </div>
            </div>
            
          <div className="motto" style={{marginTop: '83px'}}>London's Food Merch Directory - est. Lockdown 3</div>
          <Masonry  
            breakpointCols={breakpointColumnsObj} 
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column">

            {this.state.data.filter(items => items[this.state.filtergroup] === this.state.filter).slice(0, this.state.VisibleNo).map((data) => (
              <div>
                <div className="tilebutton tileposition" key={data.URL} href={data.URL} >
                  <DesktopOverlay data={data} />
                  {!!data.New && 
                  <div className="newtag">NEW</div>}
                  <img src={"/img/" + data.CompID + "/" + data.MainImg} onClick={() => this.GAtileopen(data)} href={data.URL} alt='' style={{ width: '100%', borderRadius: '4px', backgroundColor: 'white' }} />
                  <div className="itemname" style={{ overflow: 'hidden' }}>{data.Name}</div>
                  <div className="placename">{data.Company}</div>
                </div>
              </div>
            ))}
          </Masonry> 
          <div className="LoadMoreButton" onClick={this.allloadedcheck} style={{display: this.state.LoadMoreVis}}>Load More</div>
        </div>
        <div className="headermain" style={{display:this.state.MainApp}}>
          <div className="header">
            <div className="pagetitle" onClick={() => this.setState({ OverlayVis: 'none', MenuTop: '100vh', filter: 1, filtergroup: 'Status'})}><img alt='logo' src="\img\Icons\text1.png" className='titlelogo' /></div>
          </div> 
        </div> 
        <div className="hamburgericon" onClick={() => this.setState({ MenuTop: '76px', CloseButton: 'block' })}>☰</div>
        <div className="hamburgericon"  style={{display: this.state.CloseButton, zindex:10}} onClick={() => this.setState({ MenuTop: '100vh', CloseButton: 'none', maingroup: 'none', opengroup: 'none'})}>☰</div> 
        <nav className="Sidebar w3-bar-block w3-card w3-top w3-xlarge" style={{display: 'block', marginTop: this.state.MenuTop, transition: '0.2s'}} id="mySidebar">
          <div onClick={() => this.setState({ maingroup: 'Locations'})} className="SidebarItemL SideButton">Locations</div>
          <div className={this.state.maingroup === 'Locations' ? 'opengroup' : 'ClosedGroup'}>
            {locationoutput.map((group) => (
              <div className="sidebar">
                <div className="SideButton SidebarItemMed" onClick={() => this.setState({ opengroup: group})}>{group}</div>
                <div className={this.state.opengroup === group ? 'opengroup' : 'ClosedGroup'}>
                  <div onClick={() => this.setState({ filter: group, filtergroup: 'NSEW', MenuTop: '100vh', CloseButton: 'absolute', VisibleNo: 20, LoadMoreVis: 'block', maingroup: 'none', opengroup: 'none'})} className="SideButton SidebarItemSm">All</div>
                  {this.state.locations.filter(item => item.NSEW === group).map((locations) => (
                    <div className="SideButton SidebarItemSm" onClick={() => this.setState({ filter: locations.Company, filtergroup: 'Company', MenuTop: '100vh', CloseButton: 'absolute', VisibleNo: 20, LoadMoreVis: 'block', maingroup: 'none', opengroup: 'none'})}>{locations.Company}</div>))}
                </div>
              </div>))}
          </div>
          <div onClick={() => this.setState({ maingroup: 'Items' })} className="SidebarItemL SideButton">Thing Types</div>
          <div className={this.state.maingroup === 'Items' ? 'opengroup' : 'ClosedGroup'}>
            {itemoutputs.map((group) => (
            <div className="sidebar">
              <div className="SideButton SidebarItemMed" onClick={() => this.setState({ opengroup: group})}>{group}</div>
              <div className={this.state.opengroup === group ? 'opengroup' : 'ClosedGroup'}>
                <div onClick={() => this.setState({ filter: group, filtergroup: 'GroupDesc', MenuTop: '100vh', CloseButton: 'absolute', VisibleNo: 20, LoadMoreVis: 'block', maingroup: 'none', opengroup: 'none'})} className="SideButton SidebarItemSm">All</div>
                {this.state.items.filter(item => item.GroupDesc === group).map((items) => (
                  <div className="SideButton SidebarItemSm" onClick={() => this.setState({ filter: items.TypeDesc, filtergroup: 'TypeDesc', MenuTop: '100vh', CloseButton: 'absolute', VisibleNo: 20, LoadMoreVis: 'block', maingroup: 'none', opengroup: 'none'})}>{items.TypeDesc}</div>))}
              </div>
            </div>))}
          </div>
          <div onClick={() => this.setState({ maingroup: 'Businesses'})} className="SidebarItemL SideButton">Food Types</div>
          <div className={this.state.maingroup === 'Businesses' ? 'opengroup' : 'ClosedGroup'}>
            {businessoutput.map((group) => (
              <div className="sidebar"> 
                <div className="SideButton SidebarItemMed" onClick={() => this.setState({ opengroup: group})}>{group}</div>
                <div className={this.state.opengroup === group ? 'opengroup' : 'ClosedGroup'}>
                  <div onClick={() => this.setState({ filter: group, filtergroup: 'BusDesc', MenuTop: '100vh', CloseButton: 'absolute', VisibleNo: 20, LoadMoreVis: 'block', maingroup: 'none', opengroup: 'none'})} className="SideButton SidebarItemSm">All</div>
                  {this.state.businesses.filter(item => item.BusDesc === group).map((businesses) => (
                    <div className="SideButton SidebarItemSm" onClick={() => this.setState({ filter: businesses.Company, filtergroup: 'Company', MenuTop: '100vh', CloseButton: 'absolute', VisibleNo: 20, LoadMoreVis: 'block', maingroup: 'none', opengroup: 'none'})}>{businesses.Company}</div>))}
                </div> 
              </div>))}
          </div>
          <div onClick={() => this.setState({ filter: 1, filtergroup: 'New', MenuTop: '100vh', CloseButton: 'absolute', VisibleNo: 20, LoadMoreVis: 'block', maingroup: 'none', opengroup: 'none' })} className="SideButton SidebarItemL">New Stuff</div>
          <div onClick={() => this.setState({ filter: 1, filtergroup: 'Status', MenuTop: '100vh', CloseButton: 'absolute', VisibleNo: 20, LoadMoreVis: 'block', maingroup: 'none', opengroup: 'none' })} className="SideButton SidebarItemL">Show 'em all</div>
          <div onClick={() => this.setState({ filter: 25, filtergroup: 'Type', MenuTop: '100vh', CloseButton: 'absolute', VisibleNo: 20, LoadMoreVis: 'block', maingroup: 'none', opengroup: 'none' })} style={{marginTop: '50px'}} className="SideButton SidebarItemL mealkit">Meal Kits</div>
          <div onClick={() => this.setState({ filter: 24, filtergroup: 'Type', MenuTop: '100vh', CloseButton: 'absolute', VisibleNo: 20, LoadMoreVis: 'block', maingroup: 'none', opengroup: 'none' })} className="SideButton SidebarItemL mealkit">Teachings and Tastings</div>
          <div href='https://www.instagram.com/ldnfoodmerch/' onClick={() => this.setState({ filter: 1, filtergroup: 'Status', MenuTop: '100vh', CloseButton: 'absolute', VisibleNo: 20, LoadMoreVis: 'block', maingroup: 'none', opengroup: 'none' })} className="SideButton SidebarItemL instalink"><img alt='instalogo' src="\img\Icons\instagram.svg" className='instalogo' />LDNFoodMerch</div>
        </nav> 
        <img src="\img\Icons\BannerLDN-cropinv.png" className="footericon"/>
          <CookieConsent
            location="bottom"
            buttonText="ACCEPT ALL COOKIES"
            cookieName="Cookie1"
            overlay='true'
            style={{ background: "#2B373B" }}
            buttonStyle={{padding: '10px 15px',  margin: '10px 50vw 20px', transform: 'translateX(-50%)', fontsize: '15px', borderRadius: '6px', fontWeight: 'bold', backgroundColor: 'aliceblue', width: '200px'}}
            expires={150}
          > <span style={{display: 'inline-block'}}>- This website uses cookies for analytics -</span>
            <span style={{ fontSize: "10px", display: 'inline-block' }}>Please read Google's policy <a href="https://policies.google.com/privacy?hl=en-US">here</a> for more info</span>
          </CookieConsent>
      </div>
    );
  }
}

function DesktopOverlay({ data }, OverlayVis) {
  const [overlay, ShowOverlay] = useState('none');

  return (
    <div>
      <div className="tilebutton tileposition" key={data.URL} href={data.URL} >
        <div className="tileoverlay overlaymobile" id="overlay" onClick={() => { ShowOverlay('none') }} key={data.URL} style={{ display: overlay, width: '100%', zindex: '10' }}>
          <a className="button1" href={data.URL} target="_blank" rel='noreferrer'>{data.Name}</a>
          <a className="button2" href={'http://www.' + data.CompURL} target="_blank" rel='noreferrer'>{data.Company}</a>
          <a className="button2" href={'http://www.instagram.com/' + data.Insta} target="_blank" rel='noreferrer'><img alt='instalogo' src="\img\Icons\instagram.svg" className='instalogo' />{data.Insta}</a>
        </div>
      </div>
    </div>
  );
}

function GAEvent(event, button, cat) {
  ReactGA.event({
    category: cat,
    action: event,
    value: button,
    transport: 'beacon'
  });
}

//debugger; // TO INSPECT THE PAGE BEFORE 1ST RENDER

export default App;